@mixin box-cta-large {
  min-height: 3rem;
  padding: 0.25rem 1.25rem;
  font-size: 1rem;
}

@mixin box-cta-medium {
  min-height: 2.5rem;
  padding: 0.25rem 1rem;
  font-size: 1rem;
}

@mixin box-cta-small {
  min-height: 2rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
}
