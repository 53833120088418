@use '../colors' as *;
@use '../border' as *;

$placeholder-width: 81.25rem;
$default-background-color: $color-faded-gray;
$default-accent-color: $color-light-gray;

@keyframes placeholder {
  from {
    background-position: 0 0;
  }

  to {
    background-position: $placeholder-width 0;
  }
}

/**
 * Can be used instead of `placeholderElement` when pseudo elements are not accessible, e.g. in images.
 *
 * The CSS variables `--placeholder-background-color` and `--placeholder--accent-color` override the respective
 * arguments.
 */
@mixin placeholder {
  animation-name: placeholder;
  animation-duration: 1.7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-radius: $border-radius-xxs;
  background-image: linear-gradient(
    to right,
    var(--placeholder-background-color, $default-background-color) 2%,
    var(--placeholder-accent-color, $default-accent-color) 33%,
    var(--placeholder-background-color, $default-background-color) 64%
  );
  background-size: $placeholder-width;
  animation-fill-mode: forwards;
}

/**
 * Should generally be used to show placeholders. Hides the element's content.
 *
 * The CSS variables `--placeholder-background-color`, `--placeholder--accent-color` and `--placeholder-width`
 * override the respective arguments.
 */
@mixin placeholder-element {
  position: relative;
  color: transparent;

  &::before {
    @include placeholder;

    content: '';
    inset: 0;
    position: absolute;
    right: auto;
    width: var(--placeholder-width, 100%);
  }

  & > * {
    visibility: hidden;
  }
}
