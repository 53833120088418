$color-aqua: #0ba3c1;
$color-background-gray: #f5f5f5;
$color-berry: #d4496a;
$color-black: #000000;
$color-dark-aqua: #087a91;
$color-dark-berry: #952741;
$color-dark-gray: #767676;
$color-dark-green: #054d2e;
$color-dark-moss: #3a5047;
$color-dark-orange: #fa9105;
$color-dark-red: #ea180f;
$color-dark-smoke: #313c49;
$color-faded-aqua: #f1f9fa;
$color-faded-berry: #fdf6f8;
$color-faded-gray: #f5f5f5;
$color-faded-green: #f2f9f5;
$color-faded-moss: #f5f8f7;
$color-faded-red: #fef3f3;
$color-faded-smoke: #f1f4f6;
$color-gray: #b2b2b2;
$color-green: #077144;
$color-light-aqua: #75c1d0;
$color-light-berry: #e8a6b6;
$color-light-gray: #e6e6e6;
$color-light-green: #7cc199;
$color-light-moss: #9eb8ae;
$color-light-red: #f78782;
$color-light-smoke: #7390a1;
$color-moss: #658a7c;
$color-orange: #fcbc67;
$color-red: #f45750;
$color-smoke: #465b68;
$color-white: #ffffff;

$dark-colors: $color-black, $color-dark-red, $color-berry, $color-dark-berry, $color-aqua, $color-dark-aqua,
  $color-light-smoke, $color-smoke, $color-dark-smoke, $color-green, $color-dark-green, $color-moss, $color-dark-moss,
  $color-dark-gray;

/* stylelint-disable */
/* TODO: lower-case naming */
$colors: (
  Aqua: $color-aqua,
  BackgroundGray: $color-background-gray,
  Berry: $color-berry,
  Black: $color-black,
  DarkAqua: $color-dark-aqua,
  DarkBerry: $color-dark-berry,
  DarkGray: $color-dark-gray,
  DarkGreen: $color-dark-green,
  DarkMoss: $color-dark-moss,
  DarkOrange: $color-dark-orange,
  DarkRed: $color-dark-red,
  DarkSmoke: $color-dark-smoke,
  FadedAqua: $color-faded-aqua,
  FadedBerry: $color-faded-berry,
  FadedGray: $color-faded-gray,
  FadedGreen: $color-faded-green,
  FadedMoss: $color-faded-moss,
  FadedRed: $color-faded-red,
  FadedSmoke: $color-faded-smoke,
  Gray: $color-gray,
  Green: $color-green,
  LightAqua: $color-light-aqua,
  LightBerry: $color-light-berry,
  LightGray: $color-light-gray,
  LightGreen: $color-light-green,
  LightMoss: $color-light-moss,
  LightRed: $color-light-red,
  LightSmoke: $color-light-smoke,
  Moss: $color-moss,
  Orange: $color-orange,
  Red: $color-red,
  Smoke: $color-smoke,
  White: $color-white,
);

$cms-colors: (
  aqua: $color-aqua,
  background-gray: $color-background-gray,
  berry: $color-berry,
  black: $color-black,
  dark-aqua: $color-dark-aqua,
  dark-berry: $color-dark-berry,
  dark-gray: $color-dark-gray,
  dark-green: $color-dark-green,
  dark-moss: $color-dark-moss,
  dark-orange: $color-dark-orange,
  dark-red: $color-dark-red,
  dark-smoke: $color-dark-smoke,
  faded-aqua: $color-faded-aqua,
  faded-berry: $color-faded-berry,
  faded-gray: $color-faded-gray,
  faded-green: $color-faded-green,
  faded-moss: $color-faded-moss,
  faded-red: $color-faded-red,
  faded-smoke: $color-faded-smoke,
  gray: $color-gray,
  green: $color-green,
  light-aqua: $color-light-aqua,
  light-berry: $color-light-berry,
  light-gray: $color-light-gray,
  light-green: $color-light-green,
  light-moss: $color-light-moss,
  light-red: $color-light-red,
  light-smoke: $color-light-smoke,
  moss: $color-moss,
  orange: $color-orange,
  red: $color-red,
  smoke: $color-smoke,
  white: $color-white,
);

:export {
  Aqua: $color-aqua;
  BackgroundGray: $color-background-gray;
  Berry: $color-berry;
  Black: $color-black;
  DarkAqua: $color-dark-aqua;
  DarkBerry: $color-dark-berry;
  DarkGray: $color-dark-gray;
  DarkGreen: $color-dark-green;
  DarkMoss: $color-dark-moss;
  DarkOrange: $color-dark-orange;
  DarkRed: $color-dark-red;
  DarkSmoke: $color-dark-smoke;
  FadedAqua: $color-faded-aqua;
  FadedBerry: $color-faded-berry;
  FadedGray: $color-faded-gray;
  FadedGreen: $color-faded-green;
  FadedMoss: $color-faded-moss;
  FadedRed: $color-faded-red;
  FadedSmoke: $color-faded-smoke;
  Gray: $color-gray;
  Green: $color-green;
  LightAqua: $color-light-aqua;
  LightBerry: $color-light-berry;
  LightGray: $color-light-gray;
  LightGreen: $color-light-green;
  LightMoss: $color-light-moss;
  LightRed: $color-light-red;
  LightSmoke: $color-light-smoke;
  Moss: $color-moss;
  Orange: $color-orange;
  Red: $color-red;
  Smoke: $color-smoke;
  White: $color-white;
}
