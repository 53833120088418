@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.container {
  display: grid;
  grid-template-rows: [list] auto;
  padding: $spacing-m $spacing-xs;
  scroll-margin-top: $spacing-3xl;

  &:has(> .text-intro) {
    grid-template-rows: [text-intro] auto $spacing-m [list] auto;
  }

  @media (min-width: $mq-medium-and-above) {
    &,
    &:has(> .text-intro) {
      grid-template-columns: [text-intro] 6fr 1fr [list] 5fr;
      grid-template-rows: unset;
      padding: $spacing-xl max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem));
    }
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }
}

.text-intro {
  grid-area: text-intro;
}

.list {
  display: flex;
  grid-area: list;
  flex-direction: column;
  align-items: center;
  gap: $spacing-m;
  padding: $spacing-m $spacing-xs;
  border-radius: $border-radius-s;

  @media (min-width: $mq-small-and-above) {
    padding: $spacing-m;
  }

  &-bg-faded-gray {
    @include background-text-color($color-white);
  }

  &-bg-white {
    @include background-text-color($color-faded-gray);
  }
}
