@use '@lichtblick/photon/theme/global' as *;

.item {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  list-style: none;

  & > :last-child {
    flex-grow: 1;
  }
}

.paired-item {
  display: flex;
  align-items: center;
  gap: $spacing-xxs;
  margin-bottom: $spacing-xxs;
}

.paired-item-dotted-line {
  flex: 1;
  height: 1px;
  margin-top: 0.7em;
  background-image: linear-gradient(to right, $color-gray 1px, $color-white 1px);
  background-repeat: repeat-x;
  background-position: 50% 0;
  /* stylelint-disable-next-line meowtec/no-px */
  background-size: 4px 1px;
}

.icon {
  flex-shrink: 0;
  margin-top: 0.3125rem;
  margin-right: $spacing-xs;

  &-small-margin-top {
    margin-top: 0.125rem;
  }

  @each $color-name, $color-value in $cms-colors {
    &-color-#{'' + $color-name} {
      color: $color-value;
    }
  }
}
