@use '@lichtblick/photon/theme/global' as *;

%content-styles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.root {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  background: $color-black;
}

.data-privacy-layer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $spacing-xs $spacing-xxs;
  color: $color-white;
  text-align: center;

  @extend %content-styles;
}

.privacy-text {
  max-width: 23rem;
  margin: 0 0 $spacing-xxs;

  @media (min-width: $mq-small-and-above) {
    margin: $spacing-xxs 0 $spacing-xs;
  }
}

.privacy-link {
  margin: $spacing-xxs;
}

.iframe {
  border: 0;

  @extend %content-styles;
}
