@use '@lichtblick/photon/theme/global' as *;

.card {
  position: relative;
  margin-bottom: $spacing-xxs;
  overflow: hidden;
  border-radius: $border-radius-s;

  &:focus-visible {
    @include focus-ring;
  }

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      background-color: $color-value;

      @include get-text-color($color-value);
    }
  }
}

.icon {
  position: absolute;
  top: $spacing-xxs + $spacing-xs;
  right: $spacing-xxs + $spacing-xs;
  transition: opacity $large-outro;
  border-radius: 50%;
  outline: solid $spacing-xs rgba($color-white, 33%);
  background-color: rgba($color-white, 33%);
  color: $color-black;

  .card:hover &,
  .card:focus-visible & {
    opacity: 0;
  }
}

.media {
  position: relative;
  transition: opacity $large-outro;
  pointer-events: none;

  .card:hover &,
  .card:focus-visible & {
    opacity: 0;
  }
}

.text {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $spacing-m;
}
