// 4px
$spacing-3xs: 0.25rem;

// 8px
$spacing-xxs: 0.5rem;

// 16px
$spacing-xs: 1rem;

// 24px
$spacing-s: 1.5rem;

// 32px
$spacing-m: 2rem;

// 48px
$spacing-l: 3rem;

// 64px
$spacing-xl: 4rem;

// 96px
$spacing-xxl: 6rem;

// 128px
$spacing-3xl: 8rem;

$spacings: (
  3xs: $spacing-3xs,
  xxs: $spacing-xxs,
  xs: $spacing-xs,
  s: $spacing-s,
  m: $spacing-m,
  l: $spacing-l,
  xl: $spacing-xl,
  xxl: $spacing-xxl,
  3xl: $spacing-3xl,
  none: 0,
);
